<template>
  <div>
    <div v-if="getCart.length > 0">
      <b-table responsive show-empty
               :items="[...getCart]" :fields="tableHeader" primary-key="id"
               class="curva-table" tbody-tr-class="curva-table-row">
        <!--    Name with photo    -->
        <template #cell(productName)="data">
          <!--      {{data.item.product.id}}-->
          <router-link v-if="data.item.product" :to="{name: 'productDetails', params: {id: data.item.product.id}}">
            <img :src="data.item.product.image" class="table-img mx-2">
            <span>{{data.item.product.name}}</span>
          </router-link>
        </template>
        <template #cell(size)="data">
          {{data.item.info.size.name_ar}}
        </template>
        <template #cell(color)="data">
          <span class="products_color_inside d-inline-block"
                     :style="{'background-color': data.item.color }"></span>
        </template>
        <template #cell(unitPrice)="data">
          {{calcPrice(data.item.unit_price, data.item.offer)}}
        <span class="currency">{{$t('egp')}}</span>
        </template>
        <template #cell(quantity)="data">
          <b-button class="table-quantity-btn"
                    @click="changeQuantity(data.item.id, data.item.quantity -1)"
                    :disabled="data.item.quantity < 2 || clicked">-
          </b-button>
          <input class="table-quantity-text" v-model="data.item.quantity" disabled/>
          <b-button class="table-quantity-btn" :disabled="data.item.info.quantity === data.item.quantity || clicked"
                    @click="changeQuantity(data.item.id, data.item.quantity +1)">
            +</b-button>
        </template>
        <template #cell(subtotal)="data">
          {{calcPrice(data.item.total_price, data.item.offer)}}
        <span class="currency">{{$t('egp')}}</span>
        </template>
        <template #cell(action)="data">
          <button class="btn remove-btn" @click="removeItemFromCart(data.item.id)"><i
              class="las la-trash font-size-18 mr-2"></i>
            {{ $t('cart.remove') }}</button>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import CartServices from '@/modules/cart/services/cart.services'

export default {
  data () {
    return {
      clicked: false,
      purchaseDetails: {
        products: this.products,
        address: '',
        paymentType: ''
      },
      tableHeader: [
        { key: 'productName', label: this.$t('cart.name'), class: 'px-3 text-initial' },
        { key: 'size', label: this.$t('cart.size'), class: 'px-3 text-initial' },
        { key: 'color', label: this.$t('cart.color'), class: 'px-3 text-initial' },
        { key: 'unitPrice', label: this.$t('cart.unitPrice'), class: 'px-3 text-initial' },
        { key: 'quantity', label: this.$t('cart.quantity'), class: 'px-3 text-initial' },
        { key: 'subtotal', label: this.$t('cart.subtotal'), class: 'px-3 text-initial' },
        { key: 'action', label: this.$t('cart.action'), class: 'px-3 text-initial' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getCartCount', 'getCart'])
  },
  methods: {
    calcPrice (price, offer) {
      if (offer) {
        return (price - ((offer * price) / 100)).toFixed(1)
      } else {
        return price
      }
    },
    removeItemFromCart (productId) {
      this.$store.commit('removeItemFromCart', productId)
    },
    changeQuantity (id, quantity) {
      if (!this.clicked) {
        this.clicked = true
        this.$store.commit('updateQuantity', { id, quantity })
        CartServices.changeQuantity(id, { quantity: quantity }).then(res => {
          this.$store.dispatch('getCartContent')
        })
        setTimeout(() => {
          this.clicked = false
        }, 1000)
      }
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.$store.commit('cartStep', 1)
  }
}
</script>
<style>
.table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column, .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column{
  right: 0 !important;
  left: auto;
}
.bg-inherit {
  background: inherit !important;
}
</style>
